import { ApiClient } from "../../api/roomManagement/RoomManagementApiClient";
import LOG from "../../logging/Logger";
import { getApiDetails } from "./getApiDetails";

export interface SubscribeToRoomUpdatesParameters {
  roomCode: string;
  callback: (event: string) => void;
}

export async function subscribeToRoomUpdatesApi(
  subscribeToRoomUpdatesParameters: SubscribeToRoomUpdatesParameters
) {
  LOG.info(
    `Subscribe to room updates for room: ${subscribeToRoomUpdatesParameters.roomCode}`
  );
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);
  const input = { roomCode: subscribeToRoomUpdatesParameters.roomCode };
  const result = apiClient.onRoomUpdate(
    input,
    subscribeToRoomUpdatesParameters.callback
  );
  return result;
}
