import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import { checkRoomApi } from "../../../usecase/calling/checkRoom";

export interface JoinRoomState {
  isLoading: boolean;
  error?: string;
  roomConfirmed: boolean;
}

const initialState: JoinRoomState = {
  isLoading: false,
  roomConfirmed: false,
};

export const joinRoom = createAsyncThunk(
  "joinRoomSlice/joinRoom",
  async (roomCode: string) => {
    return checkRoomApi({ roomCode: roomCode });
  }
);

export const joinRoomSlice = createSlice({
  name: "joinRoom",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(joinRoom.fulfilled, (state, action) => {
      state.roomConfirmed = true;
      state.isLoading = false;
      state.error = undefined;
    });
    builder.addCase(joinRoom.pending, (state) => {
      state.isLoading = true;
      state.error = undefined;
    });
    builder.addCase(joinRoom.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const selectJoinRoomState = (state: RootState) => state.joinRoom;
export default joinRoomSlice.reducer;
