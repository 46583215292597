export const listRoomsQuery = `
  query ListRoomsQuery {
    listRooms {
      items {
        metadata
        createdBy
        roomCode
        vcId
      }
    }
  }`;

export const createRoomMutation = `
  mutation CreateRoomMutation($input: CreateRoomInput!) {
    createRoom(input: $input) {
      roomCode
      metadata
      liveContent
      vcId
      entityId
      providerId
      playbackOffset
      isPlaying
      appContentData
    }
  }`;

export const getRoomQuery = `
  query GetRoomQuery($input: getRoomInput!){
    getRoom(input: $input) {
      createdBy
      roomCode
      metadata
      vcId
      liveContent
      alphaEnabled
      playbackOffset
      isPlaying
      entityId
      appContentData
      providerId
      participants {
        items {
          id
          serialNumber
          azureUserId
          nickname
          roomCode
          metadata
          cameraHardwareEnabled
          micEnabled
          cameraEnabled
        }
      }
    }
  }`;

export const updateParticipantMutation = `
  mutation UpdateParticipantMutation($participantId: ID!, $input: ParticipantUpdateInput!) {
    updateParticipant(input: $input, participantId: $participantId) {
      id
      serialNumber
      azureUserId
      nickname
      roomCode
      metadata
      cameraHardwareEnabled
      micEnabled
      cameraEnabled
    }
  }`;

export const joinRoomMutation = `
  mutation JoinRoomMutation($input: JoinRoomInput!) {
    joinRoom(input: $input) {
      token
      participant {
        id
        serialNumber
        azureUserId
        nickname
        roomCode
        metadata
        cameraHardwareEnabled
        micEnabled
        cameraEnabled
      }
    }
  }`;

export const updateRoomMutation = `
  mutation UpdateRoomMutation($roomCode: ID!, $input: RoomUpdateInput!) {
    updateRoom(roomCode: $roomCode, input: $input) {
      isPlaying
      playbackOffset
      entityId
      appContentData
      providerId
      roomCode
    }
  }`;

export const getParticipantQuery = `
  query GetParticipantQuery($input: GetParticipantInput!){
    getParticipant(input: $input) {
      id
      serialNumber
      azureUserId
      nickname
      roomCode
      metadata
      cameraHardwareEnabled
      micEnabled
      cameraEnabled
    }
  }`;

export const onUpdateParticipantSubscription = `
  subscription onUpdateParticipant($roomCode: ID!) {
    onUpdateParticipant(roomCode: $roomCode) {
        id
        azureUserId
        metadata
        nickname
        cameraHardwareEnabled
        micEnabled
        cameraEnabled
        roomCode
    }
}`;

export const onUpdateRoomSubscription = `
  subscription onUpdateRoom($roomCode: ID!) {
    onUpdateRoom(roomCode: $roomCode) {
      createdBy
      roomCode
      metadata
      vcId
      playbackOffset
      isPlaying
      entityId
      appContentData
      providerId
  }
}`;
