declare global {
  interface Window {
    LOG_LEVEL: string;
  }
}

// To turn on debug logging, type this in the console or uncomment to get DEBUG all the time
//TODO: Handle log level based on environments
window.LOG_LEVEL = "DEBUG";

class Logger {
  debug(msg: any) {
    console.debug(msg);
  }

  info(msg: any) {
    console.log(msg);
  }

  warn(msg: any) {
    console.warn(msg);
  }

  error(msg: any) {
    console.error(msg);
  }
}

const LOG = new Logger();
export default LOG;
