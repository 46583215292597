import { ApiClient } from "../../api/roomManagement/RoomManagementApiClient";
import { ACSCallManager } from "../../call/ACSCallManager";
import { ParticipationDetails } from "../../types/participationDetails";
import { getApiDetails } from "./getApiDetails";

export interface SetCameraStateParameters {
  participationDetails: ParticipationDetails;
  state: boolean;
}

export async function setCameraStateApi(
  setCameraStateParameters: SetCameraStateParameters
) {
  await ACSCallManager.getInstance().setCameraState(
    setCameraStateParameters.state
  );
  const input = { cameraEnabled: setCameraStateParameters.state };
  const apiDetails = getApiDetails();
  await ApiClient.getInstance(apiDetails).updateParticipant(
    input,
    setCameraStateParameters.participationDetails.participant.id
  );
  return setCameraStateParameters.state;
}
