import {
  ApolloClient,
  gql,
  InMemoryCache,
  isApolloError,
  NormalizedCacheObject,
} from "@apollo/client";
import {
  getRoomQuery,
  createRoomMutation,
  joinRoomMutation,
  updateParticipantMutation,
  onUpdateRoomSubscription,
  updateRoomMutation,
  onUpdateParticipantSubscription,
} from "./queries";
import { createAuthenticatedHttpLink } from "./util/authenticatedHttpLink";
import { createWebSocketLink } from "./util/webSocketLink";
import { createSplitLink } from "./util/splitLink";
import { createAPIKeyAuthHeader } from "./util/apiKeyAuthHeader";
import {
  ApiError,
  InvalidRoomCodeError,
  CreateRoomError,
  GraphQLError,
  NetworkError,
  UnauthorisedError,
  UpdateRoomError,
} from "../ApiErrors";
import { ApiDetails } from "../../types/apiDetails";
import { isDeepEqual } from "../../utils/deepCheck";
import LOG from "../../logging/Logger";

class RoomManagementApiClient {
  private apolloClient: ApolloClient<NormalizedCacheObject> | null;
  private apiDetails: ApiDetails | null;
  private static instance: RoomManagementApiClient;

  private constructor(apiDetails: ApiDetails) {
    try {
      this.apiDetails = apiDetails;
      new URL(apiDetails.graphQLEndpoint);
      new URL(apiDetails.realtimeGraphQLEndpoint);

      const apiKeyAuthHeader = createAPIKeyAuthHeader(
        apiDetails.graphQLEndpoint,
        apiDetails.apiKey
      );

      const httpLink = createAuthenticatedHttpLink(
        apiDetails.graphQLEndpoint,
        apiKeyAuthHeader
      );
      const wsLink = createWebSocketLink(
        apiDetails.realtimeGraphQLEndpoint,
        apiKeyAuthHeader
      );

      const splitLink = createSplitLink(httpLink, wsLink);

      this.apolloClient = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache(),
      });

      LOG.debug("Completed GraphQL initialisation");
    } catch (error: any) {
      throw new ApiError(error.message);
    }
  }

  public static getInstance(apiDetails: ApiDetails): RoomManagementApiClient {
    if (
      RoomManagementApiClient.instance != null &&
      RoomManagementApiClient.instance.apiDetails &&
      isDeepEqual(RoomManagementApiClient.instance.apiDetails, apiDetails)
    ) {
      return this.instance;
    }
    RoomManagementApiClient.instance = new RoomManagementApiClient(apiDetails);
    return RoomManagementApiClient.instance;
  }

  /**
   * Subscribe to room updates
   *
   * @param {Object} input Input object, containing roomCode property
   * @param {function} onUpdate Calback function called when room is updated
   *
   * @returns {Object} subscription object. Call subscrption.unsubscribe()
   */

  onRoomUpdate(input: any, onUpdate: Function) {
    const observable = this.apolloClient?.subscribe({
      query: gql(onUpdateRoomSubscription),
      variables: input,
    });

    const observer = {
      start() {
        LOG.debug("onRoomUpdate start");
      },
      next(value: any) {
        onUpdate(value);
      },
      error(errorValue: any) {
        LOG.error(`onRoomUpdate error ${errorValue}`);
      },
      complete() {
        LOG.error("onRoomUpdate complete");
      },
    };

    return observable?.subscribe(observer);
  }

  /**
   * Gets room info for a specfied roomCode.
   *
   * @param {Object} input Input object, containing roomCode property
   * @returns Room object
   */
  async getRoom(input: any) {
    let result;
    try {
      result = await this.apolloClient?.query({
        query: gql(getRoomQuery),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return null;
    }

    if (!result || !result.data) {
      throw new ApiError("No data returned for getRoom");
    }

    if (!result.data.getRoom) {
      throw new InvalidRoomCodeError(
        `Invalid room code ${JSON.stringify(input)}`
      );
    }
    return result.data.getRoom;
  }

  /**
   * Updates the given room.
   *
   * @param {Object} input Room input object
   * @returns room id
   */
  async updateRoom(roomCode: string, input: any) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(updateRoomMutation),
        variables: { roomCode, input },
      });
    } catch (e) {
      this.handleError(e);
      return null;
    }

    if (!result || !result.data) {
      throw new ApiError("No data returned for updateRoom");
    }

    if (!result.data.updateRoom) {
      throw new UpdateRoomError(
        `Unable to update room ${JSON.stringify(input)}`
      );
    }
    return result.data.updateRoom;
  }

  /**
   * Joins the specified room, and returns participation info.
   *
   * @param {Object} input Input object, containing roomCode property
   * @returns Participant object
   */
  async joinRoom(input: any) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(joinRoomMutation),
        variables: { input },
      });
    } catch (e) {
      LOG.error(e);
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for joinRoom");
    }

    if (!result.data.joinRoom) {
      throw new InvalidRoomCodeError(
        `Invalid room code ${JSON.stringify(input)}`
      );
    }

    return result.data.joinRoom;
  }

  /**
   * Joins the specified room, and returns participation info.
   *
   * @param {Object} input Input object, containing roomCode property
   * @returns Participant object
   */
  async updateParticipant(input: any, participantId: string) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(updateParticipantMutation),
        variables: { input, participantId },
      });
    } catch (e) {
      LOG.error(e);
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for updateParticipant");
    }

    return result.data;
  }

  /**
   * Creates a new room, and returns the room info, including roomCode.
   *
   * @param {Object} input Input object, containing room properties to set on the new room.
   * @returns Room object
   */
  async createRoom(input: any) {
    let result;
    try {
      result = await this.apolloClient?.mutate({
        mutation: gql(createRoomMutation),
        variables: { input },
      });
    } catch (e) {
      this.handleError(e);
      return;
    }

    if (!result || !result.data) {
      throw new ApiError("No value returned for createRoom");
    }

    if (!result.data.createRoom) {
      throw new CreateRoomError(
        `Unable to create room ${JSON.stringify(input)}`
      );
    }

    return result.data.createRoom;
  }

  /**
   * Subscribe to participant updates of participants in the specified room
   *
   * @param {Object} input Inout object, containing the roomCode
   * @param {*} onUpdate Calback function called when participant is updated
   *
   * @returns {Object} subscription object. Call subscrption.unsubscribe()
   */
  onParticipantUpdate(input: any, onUpdate: Function) {
    const observable = this.apolloClient?.subscribe({
      query: gql(onUpdateParticipantSubscription),
      variables: input,
    });

    const observer = {
      start() {
        LOG.debug("onParticipantUpdate start");
      },
      next(value: any) {
        onUpdate(value);
      },
      error(errorValue: any) {
        LOG.error(`onParticipantUpdate error ${errorValue}`);
      },
      complete() {
        LOG.debug("onParticipantUpdate complete");
      },
    };

    return observable?.subscribe(observer);
  }

  //TODO: Check why we're using all 4xx codes for unathorised. We should use 401 only for that.
  /**
   * Return true if the error represents an unauthorised error.
   *
   * @param {ApolloError} e
   * @returns True if the error is a network error and status code is 4XX
   */
  isUnauthorised(e: any) {
    return (
      e.networkError &&
      e.networkError.statusCode / 100 === 4 &&
      e.networkError.message
    );
  }

  /**
   * Return true if the error contains any graphQL errors.
   *
   * @param {ApolloError} e
   * @returns True if error contains any graphQL errors
   */
  hasGraphQLError(e: any) {
    return (
      e.graphQLErrors &&
      e.graphQLErrors.length > 0 &&
      e.graphQLErrors[0].message
    );
  }

  isGraphQLError(e: any) {
    return (
      e.graphQLErrors &&
      e.graphQLErrors.length > 0 &&
      e.graphQLErrors[0].message
    );
  }

  /**
   * Handles the error and throws the corresponding error
   *
   * @param {Error} e The error to transform
   */
  handleError(e: any) {
    if (!isApolloError(e)) {
      throw new Error();
    }

    if (this.isUnauthorised(e)) {
      throw new UnauthorisedError(e?.networkError?.message);
    }

    if (this.isGraphQLError(e)) {
      throw new GraphQLError(e.graphQLErrors[0].message);
    }

    if (e.networkError) {
      throw new NetworkError(e.networkError.message);
    }

    throw new ApiError(e.message);
  }
}

export { RoomManagementApiClient as ApiClient };
