import { ACSCallManager } from "../../call/ACSCallManager";
import { ParticipationDetails } from "../../types/participationDetails";
import { Room } from "../../types/room";

export interface InitCallParameters {
  room: Room;
  participationDetails: ParticipationDetails;
}
export async function initCallApi(initCallParameters: InitCallParameters) {
  await ACSCallManager.getInstance().init(
    initCallParameters.room,
    initCallParameters.participationDetails
  );
}
