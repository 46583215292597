import { Features } from "@azure/communication-calling";
import { store } from "../app/store";
import {
  addLocalView,
  addRemoteParticipant,
  addRemoteParticipantView,
  removeRemoteParticipant,
  removeRemoteParticipantView,
  setDominantSpeaker,
} from "../pages/calling/slices/callingSlice";
import LOG from "../logging/Logger";
import { ParticipationDetails } from "../types/participationDetails";
import { Room } from "../types/room";
import { CallClient, VideoStreamRenderer, LocalVideoStream } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";

class ACSCallManager {
  private static instance: ACSCallManager;
  private callClient: any | null;
  private callAgent: any | null;
  private deviceManager: any | null;
  private call: any | null;
  private localVideoStream: any | null;
  private localVideoStreamRenderer: any | null;
  private participants = new Map();
  private participantsViews = new Map();

  public static getInstance(): ACSCallManager {
    if (!ACSCallManager.instance) {
      ACSCallManager.instance = new ACSCallManager();
    }

    return ACSCallManager.instance;
  }

  async init(room: Room, participationDetails: ParticipationDetails) {
    try {
      this.callClient = new CallClient();
      const authToken = participationDetails.token.trim();
      const tokenCredential = new AzureCommunicationTokenCredential(authToken);

      this.callAgent = await this.callClient.createCallAgent(tokenCredential);

      await this.initPeripherials();
      // Listen for an incoming call to accept.
      await this.joinCall(room.vcId);
    } catch (error) {
      LOG.error(error);
    }
  }

  private async initPeripherials() {
    this.deviceManager = await this.callClient.getDeviceManager();
    await this.deviceManager.askDevicePermission({ video: true });
    await this.deviceManager.askDevicePermission({ audio: true });
  }

  private async joinCall(groupId: string) {
    try {
      this.localVideoStream = await this.createLocalVideoStream();
      const videoOptions = this.localVideoStream
        ? { localVideoStreams: [this.localVideoStream] }
        : undefined;

      //this.displayLocalVideoStream();

      this.call = this.callAgent.join({ groupId }, { videoOptions });
      this.call.localVideoStreams.forEach(async (lvs: any) => {
        this.localVideoStream = lvs;
        await this.displayLocalVideoStream();
      });
      this.call.on("localVideoStreamsUpdated", (e: any) => {
        e.added.forEach(async (lvs: any) => {
          this.localVideoStream = lvs;
          await this.displayLocalVideoStream();
        });
        e.removed.forEach((lvs: any) => {
          this.removeLocalVideoStream();
        });
      });

      this.call.remoteParticipants.forEach((remoteParticipant: any) => {
        LOG.debug("Subscribe to remote particiapnt: " + remoteParticipant);
        this.subscribeToRemoteParticipant(remoteParticipant);
      });
      this.call.on("remoteParticipantsUpdated", (e: any) => {
        LOG.debug(`remoteParticipantsUpdated ${e}`);
        // Subscribe to new remote participants that are added to the call.
        e.added.forEach((remoteParticipant: any) => {
          this.subscribeToRemoteParticipant(remoteParticipant);
        });
        // Unsubscribe from participants that are removed from the call
        e.removed.forEach((remoteParticipant: any) => {
          let uuid = remoteParticipant.identifier.communicationUserId;
          LOG.debug(`WTE: removed remote participant ${uuid}`);
          this.participants.delete(uuid);
          store.dispatch(removeRemoteParticipant({ uuid: uuid }));
        });
      });

      this.sendDominantSpeaker();
      this.call
        .feature(Features.DominantSpeakers)
        .on("dominantSpeakersChanged", () => {
          this.sendDominantSpeaker();
        });

      LOG.debug("Joined call!");
      return true;
    } catch (error) {
      LOG.error(error);
    }
  }

  private sendDominantSpeaker() {
    const spearkerList = this.call.feature(Features.DominantSpeakers)
      .dominantSpeakers.speakersList;

    const speakerIdsList = spearkerList.map(
      (speaker: any) => speaker.communicationUserId
    ) as string[];

    store.dispatch(setDominantSpeaker({ uuids: speakerIdsList }));
  }

  /**
   * To render a LocalVideoStream, you need to create a new instance of VideoStreamRenderer, and then
   * create a new VideoStreamRendererView instance using the asynchronous createView() method.
   * You may then attach view.target to any UI element.
   */
  private async createLocalVideoStream() {
    const camera = (await this.deviceManager.getCameras())[0];
    if (camera) {
      return new LocalVideoStream(camera);
    } else {
      LOG.error(`No camera device found on the system`);
      return null;
    }
  }

  private async displayLocalVideoStream() {
    LOG.debug("Display local video stream.");
    try {
      this.localVideoStreamRenderer = new VideoStreamRenderer(
        this.localVideoStream
      );
      const view = await this.localVideoStreamRenderer.createView({
        scalingMode: "Crop",
      });

      this.participantsViews.set("local", view);
      store.dispatch(addLocalView());
    } catch (error) {
      LOG.error(error);
    }
  }

  private async removeLocalVideoStream() {
    try {
      this.localVideoStreamRenderer.dispose();
      //localVideoContainer.hidden = true;
    } catch (error) {
      LOG.error(error);
    }
  }

  private subscribeToRemoteParticipant(remoteParticipant: any) {
    LOG.debug(
      `WTE: added remote participant 
      ${remoteParticipant.identifier},
      ${remoteParticipant.identifier.communicationUserId}`
    );
    const uuid = remoteParticipant.identifier.communicationUserId;
    store.dispatch(addRemoteParticipant({ uuid }));

    this.participants.set(uuid, {
      acsParticipant: remoteParticipant,
    });

    try {
      // Inspect the initial remoteParticipant.state value.
      LOG.debug(`Remote participant state: ${remoteParticipant.state}`);
      // Subscribe to remoteParticipant's 'stateChanged' event for value changes.
      remoteParticipant.on("stateChanged", () => {
        LOG.debug(
          `Remote participant state changed: ${remoteParticipant.state}`
        );
      });

      // Inspect the remoteParticipants's current videoStreams and subscribe to them.
      remoteParticipant.videoStreams.forEach((remoteVideoStream: any) => {
        this.subscribeToRemoteVideoStream(remoteVideoStream, uuid);
      });
      // Subscribe to the remoteParticipant's 'videoStreamsUpdated' event to be
      // notified when the remoteParticiapant adds new videoStreams and removes video streams.
      remoteParticipant.on("videoStreamsUpdated", (e: any) => {
        LOG.debug("Video streams for remote participant has changed");
        // Subscribe to new remote participant's video streams that were added.
        e.added.forEach((remoteVideoStream: any) => {
          this.subscribeToRemoteVideoStream(remoteVideoStream, uuid);
        });
        // Unsubscribe from remote participant's video streams that were removed.
        e.removed.forEach((remoteVideoStream: any) => {
          LOG.debug("Remote participant video stream was removed.");
          store.dispatch(removeRemoteParticipantView({ uuid: uuid }));
        });
      });
    } catch (error) {
      LOG.error(error);
    }
  }

  private async subscribeToRemoteVideoStream(
    remoteVideoStream: any,
    uuid: any
  ) {
    let renderer = new VideoStreamRenderer(remoteVideoStream);
    let view: any;

    const createView = async () => {
      // Create a renderer view for the remote video stream.
      view = await renderer.createView({
        scalingMode: "Crop",
      });
      // Attach the renderer view to the UI.

      LOG.debug("Adding remote view.");
      this.participantsViews.set(uuid, view);
      store.dispatch(addRemoteParticipantView({ uuid: uuid }));
      /*const remoteVideoContainer = document.getElementById(
        "calling-remote-view"
      );
      if (remoteVideoContainer != null) {
        remoteVideoContainer.appendChild(view.target);
      }*/
    };

    // Remote participant has switched video on/off
    remoteVideoStream.on("isAvailableChanged", async () => {
      try {
        if (remoteVideoStream.isAvailable) {
          await createView();
        } else {
          view.dispose();
        }
      } catch (e) {
        LOG.error(e);
      }
    });

    // Remote participant has video on initially.
    if (remoteVideoStream.isAvailable) {
      try {
        await createView();
      } catch (e) {
        LOG.error(e);
      }
    }
  }

  async setMicrophoneState(state: boolean) {
    try {
      LOG.debug("Set microphone state: " + state);
      if (state) {
        await this.call?.unmute();
      } else {
        await this.call?.mute();
      }
    } catch (error: any) {
      LOG.error("Error: " + error);
    }
  }

  async setCameraState(state: boolean) {
    try {
      if (state) {
        this.localVideoStream = await this.createLocalVideoStream();
        this.call?.startVideo(this.localVideoStream);
      } else {
        await this.call?.stopVideo(this.localVideoStream);
      }
    } catch (error: any) {
      LOG.error("Error: " + error);
    }
  }

  hangUpCall() {
    LOG.debug("Hang up call");
    this.call?.hangUp();
  }

  getView(uuid: string) {
    return this.participantsViews.get(uuid);
  }
}
export { ACSCallManager };
