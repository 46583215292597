import LOG from "../../logging/Logger";
import { ApiClient } from "../../api/roomManagement/RoomManagementApiClient";
import { getApiDetails } from "./getApiDetails";

export async function createRoomApi() {
  LOG.info("Creating new room");
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);
  const alphaEnabled = false;
  const liveContent = false;
  const entityId = "8530720033867659112";
  const appContentData = null;
  const providerId = "8871189334638732105";

  const metadata = {
    entityId,
    lightningPlayback: true,
    playbackMode: "VIDEO_MODE_OVERLAY",
    layout: "SIDEBAR",
    contentPosition: {
      left: 0,
      top: 0,
      width: 1920,
      height: 1080,
    },
  };

  let input = {
    entityId,
    appContentData,
    providerId,
    alphaEnabled,
    liveContent,
    metadata: JSON.stringify(metadata),
    isPlaying: true,
    playbackOffset: new Date().getTime(),
  };

  const result = await apiClient.createRoom(input);
  LOG.info("Created new room.");

  return result;
}
