import { ApiClient } from "../../api/roomManagement/RoomManagementApiClient";
import LOG from "../../logging/Logger";
import { getApiDetails } from "./getApiDetails";

export interface SubscribeToParticipantUpdatesParameters {
  roomCode: string;
  callback: (event: string) => void;
}

export async function subscribeToParticipantUpdatesApi(
  subscribeToParticipantUpdatesParameters: SubscribeToParticipantUpdatesParameters
) {
  LOG.info(
    `Subscribe to participant updates for room: ${subscribeToParticipantUpdatesParameters.roomCode}`
  );
  const apiDetails = getApiDetails();
  const apiClient = ApiClient.getInstance(apiDetails);
  const input = { roomCode: subscribeToParticipantUpdatesParameters.roomCode };
  const result = apiClient.onParticipantUpdate(
    input,
    subscribeToParticipantUpdatesParameters.callback
  );
  return result;
}
