import LocalStorageManager from "../../data/LocalStorageManager";

export async function updateJoinRoomTries() {
  const localStorageManager = LocalStorageManager.getInstance();
  const tries = localStorageManager.getJoinRoomTries() ?? 0;

  const nextTries = tries + 1;
  localStorageManager.setJoinRoomTries(nextTries);
  if (nextTries >= 7) {
    localStorageManager.setJoinRoomBlockTime(new Date().getTime());
  }
}
