import { ApiDetails } from "../types/apiDetails";

class EnvironmentManager {
  private static instance: EnvironmentManager;
  public static getInstance(): EnvironmentManager {
    if (!EnvironmentManager.instance) {
      EnvironmentManager.instance = new EnvironmentManager();
    }

    return EnvironmentManager.instance;
  }

  //TODO This is not secure way to keep API Details.
  getApiDetails(): ApiDetails {
    return {
      graphQLEndpoint: "https://api.test-roomapi.together.btv.gpo.sky/graphql",
      realtimeGraphQLEndpoint:
        "wss://api.test-roomapi.together.btv.gpo.sky/graphql/realtime",
      apiKey: "da2-r532ga5rvjbszkpyoz6v3rirqe",
    };
  }
}

export default EnvironmentManager;
